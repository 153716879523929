import * as Sentry from '@sentry/vue'

export default defineNuxtPlugin(nuxtApp => {
  const router = useRouter()
  const {
    public: { sentry }
  } = useRuntimeConfig()

  if (!sentry.dsn) {
    return
  }
  if (sentry.environment === 'dev') {
    return
  }

  Sentry.init({
    app: nuxtApp.vueApp,
    dsn: sentry.dsn,
    environment: sentry.environment,
    integrations: [
      Sentry.browserTracingIntegration({ router }),
      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: false
      })
    ],

    tracesSampleRate: 0.1,
    tracePropagationTargets: [
      'localhost',
      'https://test-api.c5game.com',
      'https://test-open.c5game.com',
      'https://h5.c5game.com/napi/trade',
      'https://h5.c5game.com/napi/common'
    ],

    replaysSessionSampleRate: 0,
    replaysOnErrorSampleRate: 1.0
  })
})
