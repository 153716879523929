import Cookies from 'js-cookie'
import * as Sentry from '@sentry/vue'

export default defineNuxtRouteMiddleware(to => {
  if (process.client) {
    const initialRoute = window.sessionStorage.getItem('initialRoute')
    if (!initialRoute) {
      window.sessionStorage.setItem('initialRoute', to.fullPath)
    }
  }

  validateAcesssToken()

  // if (to.params.id === '1') {
  //   return abortNavigation()
  // }
  // In a real app you would probably not redirect every route to `/`
  // however it is important to check `to.path` before redirecting or you
  // might get an infinite redirect loop
  // if (to.path !== '/') {
  //   return navigateTo('/')
  // }
  // setPageLayout('default')
  navigateTo(to.fullPath)
})

function validateAcesssToken() {
  const keys = ['C5AccessToken']
  const missingKeys = []

  for (const key of keys) {
    if (Cookies.get(key) === undefined) {
      missingKeys.push(key)
    }
  }

  if (missingKeys.length > 0) {
    Sentry.captureException(new Error('Missing Cookies'), {
      contexts: {
        error: {
          cookie_keys: missingKeys
        }
      }
    })
  }
}
