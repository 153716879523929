import { divide } from 'number-precision'

export function getIP(headers?: HeadersInit) {
  const FIELDS = ['x-original-forwarded-for', 'proxy-client-ip', 'x-client-ip', 'x-true-ip', 'x-forwarded-for']
  for (const field of FIELDS) {
    if (!headers) {
      return
    }
    const value = Array.isArray(headers) ? headers.find(header => header[0] === field)?.[1] : (headers as Record<string, string>)[field]
    if (value !== null && value !== undefined && value.length > 0) {
      return value
    }
  }
  return null
}

export function getCookie(name: string, strCookie: string) {
  const arrCookie = strCookie ? strCookie.split(';') : []
  // eslint-disable-next-line array-callback-return
  const cookie = arrCookie.find(item => {
    if (item.includes(name)) {
      return item
    }
  })
  return cookie ? cookie.split('=')[1] : ''
}
// 防抖函数
export function debounce(func: any, wait: any) {
  let timeout: NodeJS.Timeout | null = null

  return function (...args: any[]) {
    const later = () => {
      timeout = null
      func(...args)
    }

    if (timeout !== null) {
      clearTimeout(timeout)
    }

    timeout = setTimeout(later, wait)
  }
}
// 返回简写
export function getShortName(name: string) {
  let abbName = name
  switch (name) {
    case '略有磨损':
      abbName = '略磨'
      break
    case '破损不堪':
      abbName = '破损'
      break
    case '崭新出厂':
      abbName = '崭新'
      break
    case '久经沙场':
      abbName = '久经'
      break
    case '战痕累累':
      abbName = '战痕'
      break
    case '纪念品':
      abbName = '纪'
      break
    case 'StatTrak™':
      abbName = 'ST'
      break
    case '★':
      abbName = '★'
      break
    case '英雄传世':
      abbName = '英雄'
      break
    case '亲笔签名':
      abbName = '亲笔'
      break
    default:
      break
  }
  return abbName
}

// 获取地址栏通用参数
export const getQueryParams = () => {
  const search = window.location.search
  const urlParams = new URLSearchParams(search)
  const queryParams = {} as any

  for (const [key, value] of urlParams.entries()) {
    queryParams[key] = value
  }

  return queryParams
}

// px转vw
export const pxTransformVw = (style: any) => {
  if (!style) {
    return '0vw'
  }
  const num = style?.toString().replace('px', '')
  if (process.server) {
    return `${(num / 3.75).toFixed(2)}vw`
  }

  const width = window.screen.width
  const ratio = width / 100

  // console.log(`width: ${width}; ratio: ${ratio}`)

  return `${(num / ratio).toFixed(2)}vw`
}

// vw转px
export const vwTransformPX = (style: any) => {
  if (!style) {
    return '0px'
  }
  const num = style?.toString().replace('vw', '')
  if (process.server) {
    return `${(num * 3.75).toFixed(2)}px`
  }

  const width = window.screen.width
  const ratio = width / 100

  // console.log(`width: ${width}; ratio: ${ratio}`)

  return `${(num * ratio).toFixed(2)}px`
}

// px转rem
export const px2rem = (px: number) => {
  // 将页面px转为rem
  // const html = document.documentElement
  // const rootFontSize = parseFloat(html.style.fontSize) // 设备可视区域宽度
  return Number((px / 37.5).toFixed(2))
}

export const pxToRem = (px: number) => {
  // 将页面px转为rem
  // const html = document.documentElement
  // const rootFontSize = parseFloat(html.style.fontSize) // 设备可视区域宽度
  return divide(px, 37.5) + 'rem'
}

export const disableZoom = () => {
  // e.preventDefault()  ===    阻止默认事件

  // 当一个手指放在屏幕上时，会触发 touchstart 事件。如果另一个手指又放在了屏幕上，则会先触发 gesturestart 事件
  document.addEventListener('gesturestart', function (e) {
    e.preventDefault()
  })

  // 在单个元素上单击两次 ===  dblclick
  document.addEventListener('dblclick', function (e) {
    e.preventDefault()
  })

  // 一个手指放在屏幕上时，会触发 touchstart 事件
  document.addEventListener('touchstart', function (event) {
    if (event.touches.length > 1) {
      event.preventDefault()
    }
  })

  // 如果一个或两个手指在屏幕上滑动，将会触发 gesturechange 事件
  // 但只要有一个手指移开， 就会触发 gestureend 事件，紧接着又会触发基于该手指的 touchend 事件
  let lastTouchEnd = 0
  document.addEventListener(
    'touchend',
    function (event) {
      const now = new Date().getTime()
      // 如果在300ms内触发两次touchend，就阻止默认事件
      if (now - lastTouchEnd <= 100) {
        event.preventDefault()
      }
      lastTouchEnd = now
    },
    false
  )
}

// 是否是全面屏
export const isAllScreenDisplay = () => {
  if (typeof window !== 'undefined' && window) {
    return (
      /iphone/gi.test(window.navigator.userAgent) && window.screen.height >= 724 // 原为812
    )
  }
  return false
}

export const getVantNavBarHeight = () => {
  if (typeof window !== 'undefined' && window) {
    const element = document.getElementsByClassName('van-nav-bar')?.[0]

    if (element) {
      // 获取元素的计算样式
      const computedStyle = window.getComputedStyle(element)

      // 计算内容高度（不包括padding和margin）
      return Number((element.clientHeight - parseFloat(computedStyle.paddingTop) - parseFloat(computedStyle.paddingBottom)).toFixed(2))
    }
  }
  return 0
}

export const getVantTabBarHeight = () => {
  if (typeof window !== 'undefined' && window) {
    const element = document.getElementsByClassName('van-tabbar')?.[0]

    if (element) {
      // 获取元素的计算样式
      const computedStyle = window.getComputedStyle(element)

      // 计算内容高度（不包括padding和margin）
      return Number((element.clientHeight - parseFloat(computedStyle.paddingTop) - parseFloat(computedStyle.paddingBottom)).toFixed(2))
    }
  }
  return 0
}
// 处理seo域名
export const getSeoUrl = (url: string) => {
  const hostname = window.location.hostname
  const startIndex = hostname.indexOf('.') + 1
  const domainLatter = hostname.substring(startIndex, hostname.length)
  const protocol = window.location.protocol
  return `${protocol}//c5.${domainLatter}${url}`
}

// url转base64
export const urlToBase64 = (url: string) => {
  return new Promise((resolve, reject) => {
    fetch(url)
      .then(response => response.blob())
      .then(blob => {
        const reader = new FileReader()

        reader.onloadend = () => {
          resolve(reader.result)
        }

        reader.readAsDataURL(blob)
      })
      .catch(error => {
        reject('Failed to load the url')
      })
  })
}

// 深拷贝
export const deepClone = (obj: object) => {
  const _obj = JSON.stringify(obj)
  const objClone = JSON.parse(_obj)

  return objClone
}

export async function copyToClipboard(text?: string, msg?: string) {
  if (!text) return
  try {
    await navigator.clipboard.writeText(text)
    showToast(msg || '复制成功')
  } catch (err) {
    console.error('复制失败: ', err)
  }
}
