
// Generated by dayjs-nuxt-module
import dayjs from 'dayjs'

import duration from 'dayjs/plugin/duration'
import relativeTime from 'dayjs/plugin/relativeTime'
import utc from 'dayjs/plugin/utc'


dayjs.extend(duration)
dayjs.extend(relativeTime)
dayjs.extend(utc)






export default dayjs
