function getUserAgent(ua?: string) {
  if (ua) {
    return ua.toLowerCase()
  }
  return typeof window !== 'undefined' && navigator.userAgent ? navigator.userAgent.toLowerCase() : ''
}

export function isiPhone() {
  return /iPhone/i.test(navigator.userAgent)
}

export function isiPhoneSafari() {
  // 判断是否是 Safari 浏览器
  const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent)

  return isSafari && isiPhone() && !isInWechat() && !isInQQ()
}

export function isInWechat() {
  return window.navigator.userAgent.toLowerCase().match(/MicroMessenger/i)?.[0] === 'micromessenger'
}

// ios qq浏览器
// Mozilla/5.0 (iPhone; CPU iPhone OS 17_3_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.0 MQQBrowser/14.9.6 Mobile/ 15E148 Safari/604.1 QBWebViewUA/2 QB WebViewType/1 WKType/1
// ios 内置浏览器
// Mozilla/5.0 (iPhone; CPU iPhone OS 17_3_1 like Mac OS X) AppleWebKit/6 05.1.15 (KHTML, like Gecko) Mobile/1 5E148 QQ/9.0.17.638 V1_IPH_SQ_9.0 .17_1_APP_A Pixel/1179 MiniAppEnabl e SimpleUlSwitch/1 StudyMode/0 Cu rrentMode/1 CurrentFontScale/1.000 000 QQTheme/2971 Appld/5372032 42 Core/WKWebView Device/Apple(i Phone X) NetType/WIFI QBWebViewT ype/1 WKType/1
// android qq浏览器
// Mozilla/5.0 (Linux; U; Android 13; zh-cn; M2102J2SC Build/ TKQ1.220829.002) AppleWebKit/537.36 (KHTML, like Gecko) Version/ 4.0 Chrome/109.0.5414.86 MQQBrowser/14.9 Mobile Safari/537.36 COVC/047001
// android 内置浏览器
// Mozilla/5.0(Linux; Android 13: M2102J2SC Build/TKQ1.220829.002: wv)AppleWebKit/537.36(KHTML ike Gecko) Version/4.0 Chromel 109.0.5414.86 MQQBrowser/6.2 TBS/046725 Mobile Safari/537.36 V1 AND SQ 8.3.9 356 TIM D QQ/ 3.5.6.3208 NetType/WIFI WebP/ 0.3.0 Pixel/1080 StatusBarHeight/90 SimpleUISwitch/0 QQTheme/1015712

export function isInQQ() {
  const ua = window.navigator.userAgent
  return /QQ/i.test(ua) || (/MQQBrowser/i.test(ua) && /QQ/i.test(ua.split('MQQBrowser')?.[0]))
}

// QQ内置浏览器(不包括QQ浏览器)
export function isInQQApp() {
  const ua = getUserAgent()
  // 空格 + qq
  return /\sqq/i.test(ua)
}

export function isAndroidDevice() {
  // 使用正则表达式检测用户代理字符串中是否包含 "Android" 和 "Mobile" 字符串
  const userAgent = navigator.userAgent.toLowerCase()
  const isAndroid = userAgent.includes('android')
  const isMobile = userAgent.includes('mobile')

  return isAndroid && isMobile
}

export function isMobile() {
  return 'ontouchstart' in document.documentElement
}

export function isInPC() {
  const ua = window.navigator.userAgent
  return !/Android|webOS|iPhone|iPod|BlackBerry/i.test(ua) && window.screen.width > 430 && !isMobile()
}

export function isInChrome() {
  return typeof (window as any)?.chrome !== 'undefined'
}

export function isInThirdPartyApp() {
  return isInWechat() || isInQQApp() || isInQQ()
}

// 检测ios版本是否高于15（高于15后safari导航栏移动到下方）
export function isiOSVersionAbove15() {
  const match = navigator.userAgent.match(/iPhone OS (\d+)/)
  if (match && match.length > 1) {
    const iOSVersion = parseInt(match[1], 10)
    return iOSVersion >= 15
  }
  return false
}
