import revive_payload_client_nz3wn0ZsTe from "/app/node_modules/.pnpm/nuxt@3.7.0_@parcel+watcher@2.3.0_@types+node@20.4.7_encoding@0.1.13_eslint@8.49.0_optionator@_jsiy2g2vv5uyolbeb5xnrtvuny/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import router_clydXKH0Rd from "/app/node_modules/.pnpm/nuxt@3.7.0_@parcel+watcher@2.3.0_@types+node@20.4.7_encoding@0.1.13_eslint@8.49.0_optionator@_jsiy2g2vv5uyolbeb5xnrtvuny/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import plugin_vue3_ohq9hnBFEY from "/app/node_modules/.pnpm/@pinia+nuxt@0.4.11_rollup@3.28.1_typescript@5.2.2_vue@3.4.37_typescript@5.2.2_/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/app/.nuxt/components.plugin.mjs";
import unhead_DG2NZloKzN from "/app/node_modules/.pnpm/nuxt@3.7.0_@parcel+watcher@2.3.0_@types+node@20.4.7_encoding@0.1.13_eslint@8.49.0_optionator@_jsiy2g2vv5uyolbeb5xnrtvuny/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import prefetch_client_EbJtgHnkE1 from "/app/node_modules/.pnpm/nuxt@3.7.0_@parcel+watcher@2.3.0_@types+node@20.4.7_encoding@0.1.13_eslint@8.49.0_optionator@_jsiy2g2vv5uyolbeb5xnrtvuny/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_M980Vu9Uc0 from "/app/node_modules/.pnpm/dayjs-nuxt@1.1.2_rollup@3.28.1/node_modules/dayjs-nuxt/dist/runtime/plugin.mjs";
import unocss_MzCDxu9LMj from "/app/.nuxt/unocss.mjs";
import vant_lazyload_plugin_W13KNvxip6 from "/app/.nuxt/vant-lazyload.plugin.mjs";
import chunk_reload_client_t4pftMShQb from "/app/node_modules/.pnpm/nuxt@3.7.0_@parcel+watcher@2.3.0_@types+node@20.4.7_encoding@0.1.13_eslint@8.49.0_optionator@_jsiy2g2vv5uyolbeb5xnrtvuny/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import pinia_48xmdi2HHl from "/app/plugins/pinia.ts";
import sentry_client_shVUlIjFLk from "/app/plugins/sentry.client.ts";
export default [
  revive_payload_client_nz3wn0ZsTe,
  router_clydXKH0Rd,
  plugin_vue3_ohq9hnBFEY,
  components_plugin_KR1HBZs4kY,
  unhead_DG2NZloKzN,
  prefetch_client_EbJtgHnkE1,
  plugin_M980Vu9Uc0,
  unocss_MzCDxu9LMj,
  vant_lazyload_plugin_W13KNvxip6,
  chunk_reload_client_t4pftMShQb,
  pinia_48xmdi2HHl,
  sentry_client_shVUlIjFLk
]